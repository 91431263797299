import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, GridCol, GridRow } from '@naf/grid';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { TextVariant } from '@naf/text';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';
import { useCloudinary } from '../hooks/useCloudinary';
import useSelector from '../redux/typedHooks';
import { useWindowLocation } from '../hooks/useWindowLocation';
import { actions as articleActions } from '../redux/modules/articles';
import S from '../styles/articles/StyledArticle';
import FeedbackSurvey from '../components/feedbacksurvey/FeedbackSurvey';
import BreadCrumb from '../components/breadcrumb/BreadCrumb';
import { LayoutWithMainContent } from '../components/layout/Layout';
import { SiteStructureType } from '../../../types/siteStructureType';
import ArticleGrid from '../components/article-grid/ArticleGrid';
import { InternalSearchCardType } from '../components/layout/InternalSearchMetaData';
import InfinteScroll from '../components/infinite-scroll/InfinteScroll';
import { NotFound } from '../loadable-elements/NotFound';

interface Props {
  category: SiteStructureType;
}

const AllArticlesList = ({ category }: Props) => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Artikkel-liste');

  const dispatch = useDispatch();
  const { pages, isLoadingMore, total, totalFetchedArticles, errorState } =
    useSelector((state) => state.articles.data['all-articles']) || {};
  const cld = useCloudinary();

  const onShowMoreBtnClick = () => {
    dispatch(articleActions.getMoreArticles.request(null));
  };

  useEffect(() => {
    if (!pages || (pages && Object.values(pages).length === 0)) {
      dispatch(articleActions.getArticleList.request(null));
    }
  }, [pages, category, dispatch]);

  const pageTitle = category?.seoConfig?.title || category.slug.replace('-', ' ');
  const windowPath = useWindowLocation();

  if (!category) return <NotFound />;

  if (category) {
    const categoryImage =
      (category?.image &&
        category?.image.publicId &&
        cld
          .image(category?.image.publicId)
          .resize(fill().width(1200).height(630))
          .quality('auto:best')
          .format('auto')
          .toURL()) ||
      undefined;

    return (
      <LayoutWithMainContent
        title={category.name || pageTitle}
        description={category?.ingress || category?.seoConfig?.introduction}
        url={windowPath}
        imgUrl={categoryImage || ''}
        gtmArgs={{
          page_type: 'AllArticlesListPage',
          pageCategory: category?.url || '',
          contentId: category?._id || '',
        }}
        seoTitle={pageTitle}
        seoDescription={category?.seoConfig?.introduction}
        internalSearchMetaData={{
          cardType: InternalSearchCardType.Simple,
          imagePublicId: category?.image?.publicId,
        }}
      >
        <S.Wrapper>
          <Grid>
            <GridCol s="12" m="12" l="12" xl="12">
              <BreadCrumb />
            </GridCol>
            <GridCol s="12" m="12" l="8" xl="8">
              <S.CategoryTitle tag="h1" variant={TextVariant.Header1}>
                {category?.name || pageTitle}
              </S.CategoryTitle>
              {category?.ingress && (
                <S.Ingress tag="p" variant={TextVariant.Ingress}>
                  {category.ingress}
                </S.Ingress>
              )}
            </GridCol>
            <GridRow>
              {pages && (
                <InfinteScroll
                  showLoadingTrigger={!isLoadingMore && totalFetchedArticles < total}
                  onTrigger={onShowMoreBtnClick}
                >
                  {Object.entries(pages).map(([key, { data, isLoading }]) => (
                    <ArticleGrid
                      key={key}
                      articleItems={data}
                      updating={isLoading}
                      error={typeof errorState !== 'undefined'}
                      showDate
                    />
                  ))}
                </InfinteScroll>
              )}
            </GridRow>
            <GridCol s="12" m="12" l="12" xl="12">
              <p>
                Viser {totalFetchedArticles} av {total}
              </p>
            </GridCol>
            <GridRow>
              <GridCol s="12" m="12" l="8" xl="8">
                <FeedbackSurvey contentUrl={windowPath} />
              </GridCol>
            </GridRow>
          </Grid>
        </S.Wrapper>
      </LayoutWithMainContent>
    );
  }
  return null;
};

export default AllArticlesList;
